import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import { Router } from "@reach/router";
import classNames from "classnames";
import { navigate } from "gatsby";
import React, { useState } from "react";
import { PageBody } from "../../components";
import HeaderComponent from "../../components/HeaderComponent/header-component";
import { useIsInEmbedMode } from "../../hooks";
import { useTabStyles } from "../../utils/styles-util";
import JointAccount from "./joint-account";
import PersonalAccount from "./personal-account";

const useStyles = makeStyles({
  black: {
    color: "#000000",
  },
  unselect: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AccountSetting({ location }) {
  const classes = useStyles();
  const tabsStyles = useTabStyles();
  const { pathname } = location;
  const [value, setValue] = useState(() => {
    return pathname.indexOf("joint-accounts") !== -1 ? 1 : 0;
  });
  const isInEmbedMode = useIsInEmbedMode();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    newValue === 1 && navigate("/account/joint-accounts");
    newValue === 0 && navigate("/account/");
  };

  return (
    <div className="sharing-referral-page account-page">
      <PageBody>
        <HeaderComponent
          headerContent={
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              indicatorColor="secondary"
              textColor="inherit"
              classes={{
                root: tabsStyles.tabs,
                flexContainer: tabsStyles.tabsFlexContainer,
                indicator: tabsStyles.indicator,
              }}
            >
              <Tab
                label="Personal Account"
                {...a11yProps(0)}
                classes={{
                  textColorInherit:
                    value === 0 ? classes.black : classes.unselect,
                }}
              />
              <Tab
                label="Joint Account"
                {...a11yProps(1)}
                classes={{
                  textColorInherit:
                    value === 1 ? classes.black : classes.unselect,
                }}
              />
            </Tabs>
          }
        />

        <div
          className={classNames("account-page__main-container", {
            "account-page__main-container--embed": isInEmbedMode,
          })}
        >
          <Router basepath="/account">
            <PersonalAccount path="/*" />
            <JointAccount path="/joint-accounts" />
          </Router>
        </div>
      </PageBody>
    </div>
  );
}
